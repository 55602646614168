import Vue from 'vue';
import { Client } from '@/services';
import { Services } from '../../../store/actions/api';
import { captureException } from '@sentry/vue';

export default {
  namespaced: true,
  state() {
    return {
      loading: false,
      initialized: false,
    };
  },
  getters: {
    isDataReady(state) {
      return state.initialized;
    },
    plansLoading(state) {
      return state.loading;
    },
  },
  actions: {
    initialize({ commit }) {
      console.info('ServicePlan initializing...');
      commit('initialize');
    },

    async plansRequest({ dispatch, commit }) {
      commit('loading', true);

      const params = {
        method: Services.Subscription.methods.ListServicePlans.key,
        gateway: Services.Subscription.methods.ListServicePlans.gateways.stripe,
        service: Services.Subscription.key,
      };

      try {
        const response = await Client({ params });
        return response.data.response;
      } catch (e) {
        Vue.$log.debug('There was errors during fetching subscription plans.');
        // TODO: if check if this not cause any problem in places which use servicePlans request
        captureException(e);
        return {};
      } finally {
        commit('loading', false);
      }
    },
  },

  mutations: {
    initialize(state) {
      state.initialized = true;
      console.info('ServicePlan initialized.');
    },
    loading(state, loading) {
      state.loading = loading;
    },
    plansSuccess(state, servicePlans) {
      Vue.set(state, 'servicePlans', servicePlans);
    },
  },
};
